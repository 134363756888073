<template>
  <div class="row">
    <div class="flex md12">
      <va-card :title="'Available Courses'">
        <va-data-table
          :fields="fields"
          :data="courses"
          :loading="loading"
          no-pagination
          hoverable
          clickable
          @row-clicked="gotoCourse"
        >
          <template
            slot="Active"
            slot-scope="props"
          >
            <i
              v-if=" props.rowData.Active "
              class="va-icon fa fa-check"
              style="font-size: 30px; color: green;"
            />
            <i
              v-if="!props.rowData.Active "
              class="va-icon fa fa-close"
              style="font-size: 30px; color: red;"
            />
          </template>
        </va-data-table>
      </va-card>
      <va-button
        @click="$router.push({name:'newCourse'})"
      >
        Create New
      </va-button>
    </div>
  </div>
</template>

<script>

import firebase from 'firebase';
export default {
  name: 'CourseSelection',
  data: function() {
    return {
      loading: true,
      loadingNewExam: false,
      courses: [],
      modalData: {name: null},
      showModal: false,
      translationData: {},
    };
  },
  components: {
    // OrbitSpinner,
  },
  async mounted() {
    this.getCourses();
  },
  methods: {
    getTranslationData() {
      const i8ln = firebase.firestore().collection('i18n').doc('English');
      i8ln.get().then((snapshot) => {
        this.translationData = snapshot.data();
      });
    },
    async getCourses() {
      this.loading = true;
      this.courses = [];
      const course = firebase.firestore().collection('course');

      course.where('Deleted', '==', false)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              return;
            }
            querySnapshot.forEach(async (doc) => {
              const data = await doc.data();
              if (!data) {
                return;
              }
              let model;
              if (data.model) {
                model = await data.model.get().then((model) => {
                  return model.data();
                });
              }
              this.courses.push({
                ID: doc.id,
                Name: data.Name,
                model: model,
                lessons: data.lessons,
                Active: data.Active,
              });
              this.loading = false;
            });
          });
    },
    cancelModal() {
      this.showModal = false;
    },
    saveEdit(modalData) {
      const exam = firebase.firestore().collection('Test').doc(this.modalData.id);

      exam.update({
        Name: this.modalData.Name,
        Active: this.modalData.Active,
        questions: this.modalData.questions,
      }).then(() => {
        this.showModal = false;
        this.getExams();
      });
    },
    deleteData() {
      this.remove(this.modalData);
    },
    edit(exam) {
      this.modalData = exam;
      this.showModal = true;
    },
    gotoCourse(row) {
      this.$router.push({path: 'course/' + row.ID});
    },
    remove(exam) {
      this.$swal.fire({
        title: 'Are you sure you want to delete <u>' + exam.Name + '</u>?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModal = false;
          firebase.firestore().collection('Test').doc(exam.id).update({
            Active: false,
            Deleted: true,
            DeletedAt: firebase.firestore.Timestamp.now(),
          }).then(() => {
            this.getExams();
          });
        }
        // } else if (result.isDenied) {
        //   // Swal.fire('Changes are not saved', '', 'info')
        // }
      });
    },
    createExam() {
      this.loadingNewExam = true;
      const numberOfQuestions = 10;
      const numberOfAnswers = 4;
      const defaultQuestions = [];

      for (let i = 1; i <= numberOfQuestions; i++) {
        const emptyQuestion =
                  {
                    Name: 'Question' + i,
                    Prop: '',
                    Answers: [],
                  };

        for (let q = 1; q <= numberOfAnswers; q++) {
          const EmptyAnswers =
            {
              AnswerText: 'Answer' + i + '_' + q,
              Correct: q === 1,
            };

          emptyQuestion.Answers.push(EmptyAnswers);
        }

        defaultQuestions.push(emptyQuestion);
      }
      firebase.firestore().collection('Test').add({
        CreatedDate: firebase.firestore.Timestamp.now(),
        Name: 'UNNAMED EXAM',
        PassingScore: 92,
        questions: defaultQuestions,
        Active: false,
        Deleted: false,
        Model: firebase.firestore().collection('model').doc('KvxzXocLqOaoKo0ANcdy'),
      }).then(() => {
        this.loadingNewExam = false;
        this.getExams();
      });
    },
  },
  watch: {

  },
  computed: {
    fields() {
      return [
        {
          name: 'Name',
          title: 'Course name',
        },
        {
          name: 'model.name',
          title: 'Model Name',
        },
        {
          name: '__slot:Active',
          title: 'Active',
        },
      ];
    },
  },

};
</script>
